import { bus, hotel, notes, plane } from "assets";

export const carouselCards = [
  {
    key: "tours",
    title: "tour",
    details: "tour_info_0",
    img: "https://bismillah-travel.sirv.com/tour.svg",
  },
  {
    key: "poxod",
    title: "poxod",
    details: "tour_info_1",
    img: "https://bismillah-travel.sirv.com/walk.png",
  },
  {
    key: "plane",
    title: "ticket",
    details: "tour_info_2",
    img: plane,
  },
  {
    key: "hotel",
    title: "hotels",
    details: "tour_info_3",
    img: hotel,
  },
  {
    key: "bus",
    title: "transfer",
    details: "tour_info_4",
    img: bus,
  },
  {
    key: "notes",
    title: "stra",
    details: "tour_info_5",
    img: notes,
  },
];
let id = 0;
export const toursPrices = [
  {
    id,
    img: "https://bismillah-travel.sirv.com/Images/turkey-card01.webp",
    title: "turk_river",
    location: "turk",
    sf_day: "day",
    px_night: "night",
    day: "8",
    night: "7",
    price: "10 800 000",
  },
  {
    id,
    img: "https://bismillah-travel.sirv.com/Images/turkey-card02.webp",
    title: "turk_vek",
    location: "turk",
    sf_day: "day",
    px_night: "night",
    day: "8",
    night: "7",
    price: "8 000 000",
  },
  {
    id,
    img: "https://bismillah-travel.sirv.com/Images/turkey-card03.webp",
    title: "turk_capa",
    location: "turk",
    sf_day: "day",
    px_night: "night",
    day: "8",
    night: "7",
    price: "6 800 000",
  },
  {
    id: 8,
    img: "https://bismillah-travel.sirv.com/Images/turkey-card04.webp",
    title: "srilan",
    location: "srilan",
    sf_day: "day",
    px_night: "night",
    day: "8",
    night: "7",
    price: "15 400 000",
  },
];
