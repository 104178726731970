import { useState } from "react";
import { func, bool } from "prop-types";
import { useTranslation } from "react-i18next";
import { useKeenSlider } from "keen-slider/react";
import "./style.css";
import { carouselCards } from "static";
import "keen-slider/keen-slider.min.css";
import LazyImage from "components/LazyImage";

const Carousel = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    loop: true,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
    breakpoints: {
      "(min-width: 320px)": {
        slides: {
          perView: 1,
          spacing: 3,
        },
      },
      "(min-width: 768px)": {
        slides: {
          perView: 2,
          spacing: 5,
        },
      },
      "(min-width: 1200px)": {
        breakpoints: {
          slides: {
            perView: 2,
            spacing: 5,
          },
        },
      },
    },
    slides: { perView: 3, spacing: 10 },
  });
  const { t } = useTranslation();
  return (
    <div className="navigation-wrapper">
      <div className="keen-slider" ref={sliderRef}>
        {carouselCards.map(({ key, title, details, img }) => (
          <div className="carousel__card keen-slider__slide" key={key}>
            <LazyImage
              className="card__img"
              src={img}
              alt={t(details) + t(title)}
            />
            <h1 className="card__title">{t(title)}</h1>
            <p className="card__details">{t(details)}</p>
          </div>
        ))}
      </div>
      {loaded && instanceRef.current && (
        <>
          <Arrow
            left
            onClick={(e) => e.stopPropagation() || instanceRef.current?.prev()}
            disabled={currentSlide === 0}
          />
          <Arrow
            onClick={(e) => e.stopPropagation() || instanceRef.current?.next()}
            disabled={
              currentSlide ===
              instanceRef.current.track.details.slides.length - 1
            }
          />
        </>
      )}
    </div>
  );
};

export default Carousel;
const Arrow = ({ onClick, disabled, left }) => {
  const disabledArr = disabled ? " arrow--disabled" : "";
  return (
    <svg
      className={`arrow ${
        left ? "arrow--left" : "arrow--right"
      } ${disabledArr}`}
      onClick={onClick}
      width="50px"
      height="50px"
      viewBox="0 0 54 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="27"
        cy="27"
        r="26.5"
        transform="matrix(-1 0 0 1 54 0)"
        stroke="#80A5B3"
      />
      <path
        d="M30 33L24 27L30 21"
        stroke="#80A5B3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
Arrow.propTypes = {
  left: bool,
  disabled: bool,
  onClick: func,
};
