import { number, node, bool, string } from "prop-types";
import "./style.css";

const Text = ({ type, children, weight, center }) => {
  return (
    <>
      {type === "page-title" ? (
        <h1
          className={"page-title " + (center && " centered")}
          style={{ fontWeight: weight }}
        >
          {children}
        </h1>
      ) : type === "section-title" ? (
        <h1
          className={"section-title " + (center && " centered")}
          style={{ fontWeight: weight }}
        >
          {children}
        </h1>
      ) : type === "label" ? (
        <h1
          className={"section-label " + (center && " centered")}
          style={{ fontWeight: weight }}
        >
          {children}
        </h1>
      ) : type === "gray" ? (
        <p
          className={"card-details gray " + (center && " centered")}
          style={{ fontWeight: weight }}
        >
          {children}
        </p>
      ) : type === "card" ? (
        <p
          className={"card-title " + (center && " centered")}
          style={{ fontWeight: weight }}
        >
          {children}
        </p>
      ) : type === "price" ? (
        <p
          className={"card-price " + (center && " centered")}
          style={{ fontWeight: weight }}
        >
          {children}
        </p>
      ) : (
        <p
          className={"card-details " + (center && " centered")}
          style={{ fontWeight: weight }}
        >
          {children}
        </p>
      )}
    </>
  );
};

export default Text;

Text.propTypes = {
  type: string.isRequired,
  weight: number,
  children: node,
  center: bool,
};
