import { useTranslation } from "react-i18next";
import * as c from "./style.module.css";
import Carousel from "components/Carousel";
import About from "./About/About";
import { TourPrices } from "./Prices";
import { Gallery } from "./Gallery";
import { Reviews } from "./Reviews";
import { Payments } from "./Payment";

const Home = () => {
  const { t } = useTranslation();

  return (
    <main>
      <div className={c.hero} id="home">
        <div className="container">
          <div className={c.hero__content}>
            <div className={c.content__label}>{t("hero_label")}</div>
            <h1 className={c.content__title}>
              <span>{t("title_label")} </span> {t("hero_title")}
            </h1>
          </div>
        </div>
      </div>

      <section id="services">
        <h1 className={c.section__label}>{t("category")}</h1>
        <h1 className={c.section__title}>{t("we_offer")}</h1>
        <Carousel />
      </section>
      <section id="about">
        <About />
      </section>
      <section className="container" id="services">
        <TourPrices />
      </section>
      <section id="destinations" className="container">
        <Gallery />
      </section>
      <section>
        <Reviews />
      </section>
      <section className="container">
        <Payments />
      </section>
    </main>
  );
};

export default Home;
