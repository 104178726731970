import { useState } from "react";
import { useTranslation } from "react-i18next";
import * as c from "../style.module.css";
import Text from "components/Text";
import Input from "components/Input";
import Button from "components/Button";
import LazyImage from "components/LazyImage";

export const Payments = () => {
  const { t } = useTranslation();
  const [sumToPay, setSumToPay] = useState("");
  return (
    <div className={`${c.payments__wrp} flex`}>
      <div className={c.payments__form}>
        <Text type="section-title">{t("pay")}</Text>
        <br />
        <br />
        <form
          autoComplete="off"
          method="post"
          action={`https://my.click.uz/services/pay?service_id=31248&merchant_id=23601&amount=${sumToPay}&transaction_param=test&return_url=https://bismillah-travel.uz`}
        >
          <Input
            id="payment-btn"
            type="number"
            label={t("summa")}
            value={sumToPay}
            onChange={({ target }) => setSumToPay(target.value)}
          />
          <br />
          <Button text={t("click")} hovered />
        </form>
      </div>
      <div>
        <LazyImage
          src="https://bismillah-travel.sirv.com/Images/click.webp"
          alt="Bismillah travel payments"
        />
      </div>
    </div>
  );
};
