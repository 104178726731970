import Text from "components/Text";
import * as c from "./style.module.css";
import { toursPrices } from "static";
import { TourPriceCard } from "components/Card";
import { useTranslation } from "react-i18next";

export const TourPrices = () => {
  const {t} = useTranslation();
  
  return (
    <>
      <Text type="section-title">{t("tourprice_t")}</Text>
      <div className={c.cards__wrp}>
        {toursPrices.map((price) => (
          <div key={price.title} className={c.pricecard__wrp}>
            <TourPriceCard {...price} />
          </div>
        ))}
      </div>
    </>
  );
};
