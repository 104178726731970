import Text from "components/Text";
import * as c from "./about.module.css";
import Button from "components/Button";
import LazyImage from "components/LazyImage";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();
  return (
    <div className={c.about__layout}>
      <div className={c.about__text}>
        <Text type="label">{t("about")}</Text>
        <Text type="section-title">{t("aboutpage_t")}</Text>
        <Text type="details">{t("aboutpage_d")}</Text>

        <div className={c.cards}>
          <div className={c.card}>
            <Text type="section-title" center weight={600}>
              12K+
            </Text>
            <Text type="gray" center>
              {t("about_card0")}
            </Text>
          </div>
          <div className={c.card}>
            <Text type="section-title" center weight={600}>
              16+
            </Text>
            <Text type="gray" center>
              {t("about_card1")}
            </Text>
          </div>
          <div className={c.card}>
            <Text type="section-title" center weight={600}>
              5+
            </Text>
            <Text type="gray" center>
              {t("about_card2")}
            </Text>
          </div>
        </div>

        <Button text={t("learn_more")} hovered />
      </div>
      <div className={c.about__banner}>
        <LazyImage
          src="https://bismillah-travel.sirv.com/Images/about-banner.webp"
          alt="Bismillah Travel"
        />
      </div>
    </div>
  );
};

export default About;
