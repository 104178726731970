let id = 0;
export const galleryData = [
  {
    id: 0,
    galleryImages: [
      {
        id: 0,
        key: "Турцию" + id++,
        title: "turk",
        details: "turk_river",
        img: "https://bismillah-travel.sirv.com/Images/turkey-card03.webp",
      },
      {
        id: 12,
        key: "kavkaz" + id++,
        title: "kavkaz",
        details: "",
        img: `https://bismillah-travel.sirv.com/tours/kavkaz/bg.webp`,
      },
      {
        id: 10,
        key: "Париж" + id++,
        title: "paris",
        details: "korolev",
        img: `https://bismillah-travel.sirv.com/tours/france/gl2.webp`,
      },
      {
        id: 11,
        key: "Рим" + id++,
        title: "rome",
        details: "zamok",
        img: `https://bismillah-travel.sirv.com/tours/italy/gl3.webp`,
      },
      {
        id: 13,
        key: "Европе" + id++,
        title: "europe",
        details: "tour_eu",
        img: "https://bismillah-travel.sirv.com/tours/europe/bg.webp",
      },
      {
        id: 9,
        key: "Грузию" + id++,
        title: "georgia",
        details: "monastr",
        img: "https://bismillah-travel.sirv.com/tours/georgia/gl5.webp",
      },
    ],
  },
  {
    id: 1,
    galleryImages: [
      {
        id: 1,
        key: "Египет" + id++,
        title: "egypt",
        details: "pramid",
        img: `https://bismillah-travel.sirv.com/tours/egypt/gl0.webp`,
      },
      {
        id: 1,
        key: "Египет" + id++,
        title: "egypt",
        details: "red_sea",
        img: `https://bismillah-travel.sirv.com/tours/egypt/gl1.webp`,
      },
      {
        id: 1,
        key: "Египет" + id++,
        title: "egypt",
        details: "goramuso",
        img: `https://bismillah-travel.sirv.com/tours/egypt/gl2.webp`,
      },
      {
        id: 1,
        key: "Египет" + id++,
        title: "egypt",
        details: "sfx",
        img: `https://bismillah-travel.sirv.com/tours/egypt/gl3.webp`,
      },
      {
        id: 1,
        key: "Египет" + id++,
        title: "egypt",
        details: "diving",
        img: `https://bismillah-travel.sirv.com/tours/egypt/gl4.webp`,
      },
      {
        id: 1,
        key: "Египет" + id++,
        title: "egypt",
        details: "motosafari",
        img: `https://bismillah-travel.sirv.com/tours/egypt/gl5.webp`,
      },
    ],
  },
  {
    id: 2,
    galleryImages: [
      {
        id: 2,
        key: "Азербайджан" + id++,
        title: "azar",
        details: "monastr_geo",
        img: `https://bismillah-travel.sirv.com/tours/azar/gl0.webp`,
      },
      {
        id: 2,
        key: "Азербайджан" + id++,
        title: "azar",
        details: "home_isa",
        img: `https://bismillah-travel.sirv.com/tours/azar/gl1.webp`,
      },
      {
        id: 2,
        key: "Азербайджан" + id++,
        title: "azar",
        details: "door_geo",
        img: `https://bismillah-travel.sirv.com/tours/azar/gl2.webp`,
      },
      {
        id: 2,
        key: "Азербайджан" + id++,
        title: "azar",
        details: "incity",
        img: `https://bismillah-travel.sirv.com/tours/azar/gl3.webp`,
      },
      {
        id: 2,
        key: "Азербайджан" + id++,
        title: "azar",
        details: "mosque",
        img: `https://bismillah-travel.sirv.com/tours/azar/gl4.webp`,
      },
      {
        id: 2,
        key: "Азербайджан" + id++,
        title: "azar",
        details: "bulvar",
        img: `https://bismillah-travel.sirv.com/tours/azar/gl5.webp`,
      },
    ],
  },
  {
    id: 3,
    galleryImages: [
      {
        id: 3,
        key: "Мадрид" + id++,
        title: "madrid",
        details: "madrid_musey",
        img: `https://bismillah-travel.sirv.com/tours/spain/gl0.webp`,
      },
      {
        id: 3,
        key: "Мадрид" + id++,
        title: "madrid",
        details: "san_anton",
        img: `https://bismillah-travel.sirv.com/tours/spain/gl1.webp`,
      },
      {
        id: 3,
        key: "Мадрид" + id++,
        title: "madrid",
        details: "korolev_vek",
        img: `https://bismillah-travel.sirv.com/tours/spain/gl2.webp`,
      },
      {
        id: 3,
        key: "Мадрид" + id++,
        title: "madrid",
        details: "lasventas",
        img: `https://bismillah-travel.sirv.com/tours/spain/gl3.webp`,
      },
      {
        id: 3,
        key: "Мадрид" + id++,
        title: "madrid",
        details: "santana",
        img: `https://bismillah-travel.sirv.com/tours/spain/gl4.webp`,
      },
      {
        id: 3,
        key: "Мадрид" + id++,
        title: "madrid",
        details: "bellas_art",
        img: `https://bismillah-travel.sirv.com/tours/spain/gl5.webp`,
      },
    ],
  },
  {
    id: 4,
    galleryImages: [
      {
        id: 4,
        key: "Мальдивы" + id++,
        title: "maldiv",
        details: "diving_snor",
        img: `https://bismillah-travel.sirv.com/tours/maldiv/gl0.webp`,
      },
      {
        id: 4,
        key: "Мальдивы" + id++,
        title: "maldiv",
        details: "delfin",
        img: `https://bismillah-travel.sirv.com/tours/maldiv/gl1.webp`,
      },
      {
        id: 4,
        key: "Мальдивы" + id++,
        title: "maldiv",
        details: "surfing",
        img: `https://bismillah-travel.sirv.com/tours/maldiv/gl2.webp`,
      },
      {
        id: 4,
        key: "Мальдивы" + id++,
        title: "maldiv",
        details: "sea",
        img: `https://bismillah-travel.sirv.com/tours/maldiv/gl3.webp`,
      },
      {
        id: 4,
        key: "Мальдивы" + id++,
        title: "maldiv",
        details: "ocean",
        img: `https://bismillah-travel.sirv.com/tours/maldiv/gl4.webp`,
      },
      {
        id: 4,
        key: "Мальдивы" + id++,
        title: "maldiv",
        details: "relax",
        img: `https://bismillah-travel.sirv.com/tours/maldiv/gl5.webp`,
      },
    ],
  },
  {
    id: 5,
    galleryImages: [
      {
        id: 5,
        key: "Медина" + id++,
        title: "madina",
        details: "alkuba",
        img: `https://bismillah-travel.sirv.com/tours/umra/gl0.webp`,
      },
      {
        id: 5,
        key: "Медина" + id++,
        title: "madina",
        details: "sad",
        img: `https://bismillah-travel.sirv.com/tours/umra/gl1.webp`,
      },
      {
        id: 5,
        key: "Медина" + id++,
        title: "madina",
        details: "quran",
        img: `https://bismillah-travel.sirv.com/tours/umra/gl2.webp`,
      },
      {
        id: 5,
        key: "Медина" + id++,
        title: "madina",
        details: "uhud",
        img: `https://bismillah-travel.sirv.com/tours/umra/gl3.webp`,
      },
      {
        id: 5,
        key: "Медина" + id++,
        title: "madina",
        details: "albaki",
        img: `https://bismillah-travel.sirv.com/tours/umra/gl4.webp`,
      },
      {
        id: 5,
        key: "Медина" + id++,
        title: "madina",
        details: "arafat",
        img: `https://bismillah-travel.sirv.com/tours/umra/gl5.webp`,
      },
    ],
  },
  {
    id: 6,
    galleryImages: [
      {
        id: 6,
        key: "Пхукет" + id++,
        title: "pxuket",
        details: "ostrova",
        img: `https://bismillah-travel.sirv.com/tours/tailand/gl0.webp`,
      },
      {
        id: 6,
        key: "Пхукет" + id++,
        title: "pxuket",
        details: "beach",
        img: `https://bismillah-travel.sirv.com/tours/tailand/gl1.webp`,
      },
      {
        id: 6,
        key: "Пхукет" + id++,
        title: "pxuket",
        details: "bangla",
        img: `https://bismillah-travel.sirv.com/tours/tailand/gl2.webp`,
      },
      {
        id: 6,
        key: "Пхукет" + id++,
        title: "pxuket",
        details: "zakati",
        img: `https://bismillah-travel.sirv.com/tours/tailand/gl3.webp`,
      },
      {
        id: 6,
        key: "Пхукет" + id++,
        title: "pxuket",
        details: "diving_snor",
        img: `https://bismillah-travel.sirv.com/tours/tailand/gl4.webp`,
      },
    ],
  },
  {
    id: 7,
    galleryImages: [
      {
        id: 7,
        key: "Фукуок" + id++,
        title: "fukok",
        details: "beach_ostro",
        img: `https://bismillah-travel.sirv.com/tours/vietnam/gl0.webp`,
      },
      {
        id: 7,
        key: "Фукуок" + id++,
        title: "fukok",
        details: "kanat",
        img: `https://bismillah-travel.sirv.com/tours/vietnam/gl1.webp`,
      },
      {
        id: 7,
        key: "Фукуок" + id++,
        title: "fukok",
        details: "vinland",
        img: `https://bismillah-travel.sirv.com/tours/vietnam/gl2.webp`,
      },
      {
        id: 7,
        key: "Фукуок" + id++,
        title: "fukok",
        details: "lux_service",
        img: `https://bismillah-travel.sirv.com/tours/vietnam/gl3.webp`,
      },
      {
        id: 7,
        key: "Фукуок" + id++,
        title: "fukok",
        details: "affordable_price",
        img: `https://bismillah-travel.sirv.com/tours/vietnam/gl4.webp`,
      },
      {
        id: 7,
        key: "Фукуок" + id++,
        title: "fukok",
        details: "memories",
        img: `https://bismillah-travel.sirv.com/tours/vietnam/gl5.webp`,
      },
    ],
  },
  {
    id: 8,
    galleryImages: [
      {
        id: 8,
        key: "Ланка" + id++,
        title: "srilan",
        details: "landshaft",
        img: `https://bismillah-travel.sirv.com/tours/sri-lanka/gl0.webp`,
      },
      {
        id: 8,
        key: "Ланка" + id++,
        title: "srilan",
        details: "selontea",
        img: `https://bismillah-travel.sirv.com/tours/sri-lanka/gl1.webp`,
      },
      {
        id: 8,
        key: "Ланка" + id++,
        title: "srilan",
        details: "fruit_ray",
        img: `https://bismillah-travel.sirv.com/tours/sri-lanka/gl2.webp`,
      },
      {
        id: 8,
        key: "Ланка" + id++,
        title: "srilan",
        details: "dostrop",
        img: `https://bismillah-travel.sirv.com/tours/sri-lanka/gl3.webp`,
      },
      {
        id: 8,
        key: "Ланка" + id++,
        title: "srilan",
        details: "live_world",
        img: `https://bismillah-travel.sirv.com/tours/sri-lanka/gl4.webp`,
      },
      {
        id: 8,
        key: "Ланка" + id++,
        title: "srilan",
        details: "diving_snor",
        img: `https://bismillah-travel.sirv.com/tours/sri-lanka/gl5.webp`,
      },
    ],
  },
  {
    id: 9,
    galleryImages: [
      {
        id: 9,
        key: "Грузию" + id++,
        title: "tbilisi",
        details: "rustav",
        img: `https://bismillah-travel.sirv.com/tours/georgia/gl0.webp`,
      },
      {
        id: 9,
        key: "Грузию" + id++,
        title: "tbilisi",
        details: "door",
        img: `https://bismillah-travel.sirv.com/tours/georgia/gl1.webp`,
      },
      {
        id: 9,
        key: "Грузию" + id++,
        title: "tbilisi",
        details: "kafedr",
        img: `https://bismillah-travel.sirv.com/tours/georgia/gl2.webp`,
      },
      {
        id: 9,
        key: "Грузию" + id++,
        title: "tbilisi",
        details: "garden",
        img: `https://bismillah-travel.sirv.com/tours/georgia/gl3.webp`,
      },
      {
        id: 9,
        key: "Грузию" + id++,
        title: "tbilisi",
        details: "park",
        img: `https://bismillah-travel.sirv.com/tours/georgia/gl4.webp`,
      },
      {
        id: 9,
        key: "Грузию" + id++,
        title: "tbilisi",
        details: "monastr_jvari",
        img: `https://bismillah-travel.sirv.com/tours/georgia/gl5.webp`,
      },
    ],
  },
  {
    id: 10,
    galleryImages: [
      {
        id: 10,
        key: "Париж" + id++,
        title: "paris",
        details: "madrid_musey",
        img: `https://bismillah-travel.sirv.com/tours/france/gl0.webp`,
      },
      {
        id: 10,
        key: "Париж" + id++,
        title: "paris",
        details: "san_anton",
        img: `https://bismillah-travel.sirv.com/tours/france/gl1.webp`,
      },
      {
        id: 10,
        key: "Париж" + id++,
        title: "paris",
        details: "korolev_vek",
        img: `https://bismillah-travel.sirv.com/tours/france/gl2.webp`,
      },
      {
        id: 10,
        key: "Париж" + id++,
        title: "paris",
        details: "lasventas",
        img: `https://bismillah-travel.sirv.com/tours/france/gl3.webp`,
      },
      {
        id: 10,
        key: "Париж" + id++,
        title: "paris",
        details: "santana",
        img: "https://bismillah-travel.sirv.com/tours/france/gl4.webp",
      },
      {
        id: 10,
        key: "Париж" + id++,
        title: "paris",
        details: "bellas_art",
        img: `https://bismillah-travel.sirv.com/tours/france/gl5.webp`,
      },
    ],
  },
  {
    id: 11,
    galleryImages: [
      {
        id: 11,
        key: "Рим" + id++,
        title: "rome",
        details: "ploshad",
        img: `https://bismillah-travel.sirv.com/tours/italy/gl0.webp`,
      },
      {
        id: 11,
        key: "Рим" + id++,
        title: "rome",
        details: "vitto",
        img: `https://bismillah-travel.sirv.com/tours/italy/gl1.webp`,
      },
      {
        id: 11,
        key: "Рим" + id++,
        title: "rome",
        details: "villa",
        img: `https://bismillah-travel.sirv.com/tours/italy/gl2.webp`,
      },
      {
        id: 11,
        key: "Рим" + id++,
        title: "rome",
        details: "angela",
        img: `https://bismillah-travel.sirv.com/tours/italy/gl3.webp`,
      },
      {
        id: 11,
        key: "Рим" + id++,
        title: "rome",
        details: "trast",
        img: "https://bismillah-travel.sirv.com/tours/italy/gl4.webp",
      },
      {
        id: 11,
        key: "Рим" + id++,
        title: "rome",
        details: "madjor",
        img: `https://bismillah-travel.sirv.com/tours/italy/gl5.webp`,
      },
    ],
  },
];
export const googleCardsData = [
  {
    id: id++,
    key: "Путешествие" + id++,
    title: "otdix",
    details: "review_0",
    name: "Екатерина Иванова",
    img: "https://bismillah-travel.sirv.com/Images/googlecard01.webp",
  },
  {
    id: id++,
    key: "Удовольствия" + id++,
    title: "lutshe",
    details: "review_1",
    name: "Мирзабек Абдусаттаров",
    img: "https://bismillah-travel.sirv.com/Images/googlecard02.webp",
  },
  {
    id: id++,
    key: "Впечатлениями" + id++,
    title: "emotion",
    details: "review_2",
    name: "Санжар Маликов",
    img: "https://bismillah-travel.sirv.com/Images/googlecard03.webp",
  },
];
