import { useState } from "react";
import { string, any, func } from "prop-types";
import "./style.css";

const Input = ({
  className,
  id,
  label,
  placeholder,
  type,
  value,
  onChange,
  name,
}) => {
  const [isInpActive, setIsInpActive] = useState(false);
  const inputClass = `${className} input__wrp ${
    type === "date" ? "date-input" : ""
  }`;

  return (
    <div className={inputClass}>
      <label
        htmlFor={id}
        className={
          type === "date" || isInpActive ? "label label__active" : "label"
        }
      >
        {label}
      </label>
      <input
        id={id}
        type={type ?? "text"}
        placeholder={placeholder}
        required
        name={name}
        value={value}
        onChange={onChange}
        min={
          type === "date"
            ? new Date().toISOString().split("T")[0]
            : type === "number"
            ? 1000
            : 1
        }
        onFocus={() => setIsInpActive(true)}
        onBlur={() => setIsInpActive(!!value)}
      />
    </div>
  );
};

export default Input;

Input.propTypes = {
  className: string,
  id: string.isRequired,
  label: string.isRequired,
  placeholder: string,
  type: string,
  name: string,
  value: any,
  onChange: func.isRequired,
};
