let route_id = 0;
const nav_routes = [
  {
    id: route_id++,
    path: "about",
  },
  {
    id: route_id++,
    path: "services",
  },
  {
    id: route_id++,
    path: "destinations",
  },
  {
    id: route_id++,
    path: "contacts",
  },
];
export default nav_routes;
