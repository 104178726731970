import { string } from "prop-types";
import { useEffect, useRef, useState } from "react";

const LazyImage = ({ src, alt, className }) => {
  const [imageSrc, setImageSrc] = useState("");
  const imageRef = useRef(null); // Fix: remove space between useRef and null

  useEffect(() => {
    let observer;
    if (imageRef.current) {
      observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setImageSrc(src);
              observer.unobserve(entry.target);
            }
          });
        },
        { threshold: 0.1 }
      );
      observer.observe(imageRef.current);
    }
    return () => {
      if (observer && imageRef.current) {
        observer.unobserve(imageRef.current);
      }
    };
  }, [src]);

  return <img className={className} ref={imageRef} src={imageSrc} alt={alt} />;
};

export default LazyImage;
LazyImage.propTypes = {
  src: string.isRequired,
  alt: string,
  className: string,
};
