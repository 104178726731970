import { string, bool, number } from "prop-types";
import "./style.css";
import Text from "components/Text";
import Button from "components/Button";
import LazyImage from "components/LazyImage";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const TourPriceCard = ({
  id,
  img,
  title,
  location,
  day,
  price,
  noBtn,
  noLink,
  night,
  px_night,
  sf_day,
}) => {
  const { t } = useTranslation();
  return (
    <div className="tourprice__card">
      <Link to={!noLink && "/tours/" + id}>
        <div className="img__wrp">
          <LazyImage src={img} alt={title + " tour "} />
        </div>
        <Text type="card" center>
          {t(title)}
        </Text>
        <p className="card__date">{t(location)}</p>
        <small className="card__date">
          {day} {t(sf_day)} / {night} {t(px_night)}
        </small>
        <Text type="price">{price && t("from") + price + t("sum")}</Text>

        {noBtn && <Button text={t("book")} hovered />}
      </Link>
    </div>
  );
};

TourPriceCard.propTypes = {
  id: number,
  noBtn: bool,
  img: string,
  title: string,
  location: string,
  price: string,
  day: string,
  night: string,
  sf_day: string,
  px_night: string,
  noLink: bool,
};
