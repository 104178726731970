import i18n from "lang";
import { useEffect, useState } from "react";
import { bool, func } from "prop-types";
import { Link } from "react-router-dom";
import { flags, logo } from "assets";
import nav_routes from "routes";
import * as c from "./style.module.css";
import { scrollToTop } from "utils";
import BarsIcon from "components/BarsIcon";
import { useTranslation } from "react-i18next";

const Navbar = ({ isSidebarOpen, setIsSidebarOpen, isPageScrolled }) => {
  const handleScroll = (el) => {
    el.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const { t } = useTranslation();
  const [currentLang, setcurrentLang] = useState("uz");
  const [selectedID, setselectedID] = useState(0);
  const [isDropdownOpened, setisDropdownOpened] = useState(false);
  const handleChangeLang = (id, val) => {
    setselectedID(id);
    setcurrentLang(val);
    setisDropdownOpened(false);
  };
  useEffect(() => {
    i18n.changeLanguage(currentLang);
    setisDropdownOpened(false);
  }, [currentLang]);
  return (
    <div
      className={
        isPageScrolled ? `${c.navbar} ${c.navbar__scrolled}` : `${c.navbar}`
      }
    >
      <div className="container flex">
        <div className={c.nav__logo} onClick={scrollToTop}>
          <Link className="flex" to="/">
            <img src={logo} alt="Bismillah Travel" />
            <span>Bismillah Travel</span>
          </Link>
        </div>

        <nav className="flex">
          <ul className={c.nav__menu}>
            <li className={c.menu__item}>
              <Link
                className={c.menu__link}
                to="/"
                onClick={() => window.scrollTo(0, 0)}
              >
                {t("home")}
              </Link>
            </li>
            {nav_routes.map(({ id, path }) => (
              <li key={id} className={c.menu__item}>
                <Link
                  className={c.menu__link}
                  to={path}
                  onClick={(e) => {
                    e.preventDefault();
                    const targetElement = document.getElementById(path);
                    const homeElement = document.getElementById("home");

                    if (targetElement) {
                      handleScroll(targetElement);
                    } else if (homeElement) {
                      handleScroll(homeElement);
                    }
                  }}
                >
                  {t(path)}
                </Link>
              </li>
            ))}
          </ul>
          <div className={c.languages}>
            <div
              className={c.currentLang}
              onClick={() => setisDropdownOpened(!isDropdownOpened)}
            >
              <img
                className={c.lang__img}
                src={flags[selectedID].img}
                alt="Bismillah Travel"
              />
            </div>
            <div
              className={
                isDropdownOpened ? `${c.overlay} ${c.active}` : c.overlay
              }
            >
              {flags.map(({ id, img, value }) => (
                <div
                  className={c.lang__wrp}
                  key={id}
                  onClick={() => handleChangeLang(id, value)}
                >
                  <img
                    className={c.lang__img}
                    src={img}
                    alt="Bismillah Travel"
                  />
                </div>
              ))}
            </div>
          </div>
          <BarsIcon
            isSidebarOpen={isSidebarOpen}
            setIsSidebarOpen={setIsSidebarOpen}
          />
        </nav>
      </div>
    </div>
  );
};

export default Navbar;

Navbar.propTypes = {
  isSidebarOpen: bool.isRequired,
  isPageScrolled: bool.isRequired,
  setIsSidebarOpen: func.isRequired,
};
